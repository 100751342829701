import baseURL from "@/utils/config"
import request from "@/plugins/axios"
const langEnv = window.localStorage.getItem("langEnv")
// 新客--轮播图and优惠券 clientType 1.PC 2APP
export function getBannerAndCouponRith (data) {
  return request({
    url: `${baseURL.api}/api/product/bannerAndCouponRith`,
    method: "post",
    data,
  })
}
// 新客--活动查询
export function getActivityAndProductRith (data) {
  return request({
    url: `${baseURL.api}/api/product/getActivityAndProductRith`,
    method: "post",
    data,
  })
}
// 自动领取优惠券
export function takeCoupons (data) {
  return request({
    url: `${baseURL.api}/authapi/user/takeCoupons`,
    method: "post",
    data,
  })
}
